import React, { Component } from "react"
import styles from "./PrivacyPolicy.module.scss"
import { Row, Col } from "reactstrap"
import Layout from "../components/layout/layout"

class PrivacyPolicy extends Component {
  render() {
    return (
      <section>
        <Layout>
          <Row className=" py-5 mt-5  px-4 px-0">
            <Col className="text-justify px-3 px-lg-5 py-4 " sm="12" md="12">
              <h1
                className={
                  styles.titledark + " pb-0 pt-0 w-100 text-justify mx-auto"
                }
              >
                Privacy policy
              </h1>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  Introduction
                </h3>
                <p>
                  Smart Sunday, (hereinafter "we" or "us"), is a company
                  registered in Harju Maaokond, Tallin (Estonia) whose
                  registered office is Jarvevana tee 9-40, Kesklinna linnaosa,
                  11314, and whose registered number is 14580113. We are
                  committed to protecting and respecting your privacy and to
                  providing clear information about the use of your data. We are
                  the data controller for the purposes of the personal data we
                  collect via our web or mobile site and for the performance of
                  the services listed under the usages of your personal data,
                  below (together, the “Services”).
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  Use of our online Services
                </h3>
                <p>
                  <span className={styles.intalSub}>
                    Collection of personal data
                  </span>
                </p>
                <p>
                  Personal data, or personal information, means any information
                  about an individual from which that person can be identified.
                  It does not include data where the identity has been removed
                  (anonymous data).
                </p>
                <p>
                  ‍We have access to personal data that we collect from the
                  Users when they personally register, when it is provided
                  through legal or voluntary representation or when it is
                  provided by third parties that are associated to the use of
                  our Services. The Personal Data we collect may include and may
                  not be limited to:
                </p>
                <ul>
                  <li>
                    Identifying and contact information including, your email
                    address, telephone number, name, surname, user name or
                    similar identifier, date of birth, gender, information from
                    identity document, copy of an identity document, photograph,
                    User’s bank account information, job role, area of work,
                    preferred language and postal address.
                  </li>
                  <li>
                    Account and financial data including, account information,
                    account balance, transaction data, loan data, securities
                    data, investment data and insurance data, etc. Such data may
                    be gathered from payment initiation services, from opening
                    and the subsequent use of a segregated account, from account
                    services from any P2P Providers and Loan Originators
                    investment services bound to the platform for the duration
                    of the transmission.
                  </li>
                  <li>
                    Due diligence data, including but not limited to source of
                    funds and intended business relationship.
                  </li>
                  <li>
                    Technical data including, your internet protocol (IP)
                    address, your login data, browser type and version, time
                    zone setting and location, browser plug-in types and
                    versions, operating system and platform and other technology
                    on the devices you use to access this website.
                  </li>
                  <li>
                    Profile data including, your username, purchases or orders
                    to buy or sell made by you, your interests, preferences,
                    feedback and survey responses.
                  </li>
                  <li>
                    Usage data including information on how you use our website
                    and interact with the products and services.
                  </li>
                  <li>
                    Marketing and communication data in the case you have given
                    express consent that could include your communication
                    preferences and your preferences in receiving marketing from
                    us and our third parties.
                  </li>
                </ul>
                <p>
                  We also collect, use and share Aggregated Data such as
                  statistical or demographic data. Aggregated data could be
                  derived from your personal data but is not considered personal
                  data in law as this data will not directly or indirectly
                  reveal your identity. For example, we may aggregate your Usage
                  Data to calculate the number of Users accessing a particular
                  feature. If we connect or combine Aggregated Data with
                  personal data in such a way that can directly or indirectly
                  identify you, we treat the combined data as personal data
                  which will be used in accordance with this Privacy Policy.
                </p>
                <p>
                  The way we collect, use, process and store your Personal Data
                  in order to provide the Services, is detailed in this Policy.
                </p>
                <p>
                  Occasionally we may need to collect information about criminal
                  convictions and offences. We may, in certain circumstances,
                  require the explicit consent of the data subject. Should such
                  a need arise we will contact you. In such cases we will apply
                  enhanced security measures to ensure that the privacy of the
                  data is maintained and will only process it in accordance with
                  the purposes as set out below.
                </p>
                <p>
                  <span className={styles.intalSub}>
                    If you fail to provide personal data
                  </span>
                </p>
                <p>
                  Where we need to collect personal data by law, or under the
                  terms of a contract we have with you, and you fail to provide
                  that data when requested, we may not be able to perform the
                  contract we have or are trying to enter into with you (for
                  example, to provide you with goods or services). In this case,
                  we may have to cancel a product or service you have with us
                  but we will notify you if this is the case at the time.
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  Personal Data
                </h3>
                <p>
                  <span className={styles.intalSub}>
                    ‍Use of your personal data
                  </span>
                </p>
                <p>
                  In order to use the Services, you will need to be registered
                  and be using the services of some of the companies we
                  collaborate with, including in the following circumstances:
                </p>
                <ul>
                  <li>
                    When it is necessary to perform our contract with you for
                    the service, account or product required. For example:
                  </li>
                  <ul>
                    <li>To register as a new client or user of Smart Monday</li>
                    <li>To manage the payments and transfers of funds</li>
                    <li>
                      To register with platforms linked with the Services such
                      as:
                    </li>
                    <ul>
                      <li>Payment initiation services.</li>
                      <li>
                        Services to open and subsequent use of a segregated
                        account.
                      </li>
                      <li>
                        Attached P2P or Loan Origination investment services.
                      </li>
                    </ul>
                  </ul>
                </ul>
                <p>
                  We will only proceed to use your personal data in the way
                  described above once you have expressed your agreement with
                  our Terms and Conditions.
                </p>
                <ul>
                  <li>
                    To inform you of changes to our Privacy Policy and our Terms
                    and Conditions and send you information related to the
                    Services.
                  </li>
                  <li>
                    When it is necessary to satisfy our legitimate interests (or
                    those of a third party) and your fundamental rights do not
                    override this interests, for example:
                  </li>
                  <ul>
                    <li>
                      To carry out market research and statistical analysis.
                    </li>
                    <li>
                      To administer and protect our business and website,
                      including troubleshooting, problem solving, system
                      maintenance, data analysis, testing, support, reporting
                      and hosting data.
                    </li>
                    <li>
                      To use data analytics to improve our website, services,
                      products, user experience and marketing.
                    </li>
                    <li>
                      To inform you of updates, changes or developments of our
                      products and services.
                    </li>
                    <li>To carry out user identity checks.</li>
                    <li>
                      When necessary to comply with our legal obligations.
                    </li>
                    <li>
                      To prevent, investigate and discover fraudulent
                      activities.
                    </li>
                    <li>
                      For third parties that take part in the User debt
                      collection process (such as debt collectors, lawyers,
                      bailiffs, insolvency administrators etc.).
                    </li>
                    <li>
                      To correctly develop the Services in as much as the
                      information is necessary to provide our contractual
                      services.{" "}
                    </li>
                    <li>
                      For third parties that provide legal, accounting, or audit
                      services to Smart Monday.
                    </li>
                  </ul>
                  <li>
                    When it is necessary for us to effectively comply with legal
                    and regulatory obligations.
                  </li>
                </ul>
                <p>
                  In addition, during data collection, it is possible to express
                  your consent to allow the use of your data for other uses
                  which are not related to the principal list such as:
                </p>
                <ul>
                  <li>
                    <p>
                      When you give your express consent for us to send
                      commercial communications to the postal address, email
                      address or telephone you have indicated, allowing you to
                      receive calls and text messages (SMS) about products or
                      promotions from Smart Monday or Collaborating Companies
                      and their affiliates.
                    </p>
                    <p>
                      You have the right to withdraw consent to marketing at any
                      time by contacting us.
                    </p>
                  </li>
                </ul>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  How we collect your personal data
                </h3>
                <p>
                  We use different methods to collect your personal data which
                  include:
                </p>
                <ul>
                  <li>
                    {" "}
                    <span className={styles.subUl}>Direct interactions:</span>
                    You may provide us personal data when you complete forms on
                    our site, including when you:
                  </li>
                  <ul>
                    <li>Contract with our products or services </li>
                    <li>Create an account on our website</li>
                    <li>Subscribe to one of our services or publications</li>
                    <li>Request marketing to be sent to you</li>
                    <li>Participate in a competition, promotion or survey</li>
                    <li>Send us your feedback</li>
                  </ul>
                  <li>
                    {" "}
                    <span className={styles.subUl}>
                      Automated technologies or interactions:{" "}
                    </span>{" "}
                    Whilst interacting with our website, we can automatically
                    collect technical data about your equipment, browsing
                    actions and habits. We collect this data through cookies,
                    server logs or similar technologies. Please consult our
                    cookies policy to find more information.{" "}
                  </li>
                  <li>
                    <span className={styles.subUl}>
                      Third Parties or publicly available sources:
                    </span>{" "}
                    We can receive personal data about you through various third
                    parties and publicly available sources including:
                  </li>
                  <ul>
                    <li>
                      Identifying Data for registration in such that they are
                      also required to provide the Service.
                    </li>
                    <li>
                      Contact, transactional, bank, KYC and finance data from
                      technical service providers, payment initiation services
                      providers and aggregators of associated accounts.
                    </li>
                    <li>
                      Identity and contact data from P2P Providers and Loan
                      Originators investment services bound to this platform.
                    </li>
                    <li>
                      Identity and contact data from the use of segregated
                      accounts on the platform.
                    </li>
                  </ul>
                </ul>
                <p>
                  <span className={styles.intalSub}>
                    How do I remove my consent?
                  </span>
                </p>
                <p>
                  If you change your mind, you are able to remove your consent
                  by contacting us at our email or postal address which is found
                  in the contact information.{" "}
                </p>
                <p>
                  <span className={styles.intalSub}>Change in purpose</span>
                </p>
                <p>
                  We will only use your personal data for the purposes for which
                  it was collected unless we reasonably consider that we need to
                  use it for another purpose which is compatible with the
                  original purpose. If you wish to get an explanation as to how
                  the processing for the new purpose is compatible with the
                  original purpose, please contact us.
                </p>
                <p>
                  If we need to use your personal data for a purpose that is not
                  related to the purpose for which we collected your data, we
                  will notify you explaining the legal basis which allows us to
                  do so.
                </p>
                <p>
                  Please note that we may process your personal data without
                  your knowledge or consent, in compliance with the above rules,
                  where this is required or permitted by law.
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  Limits on keeping personal data
                </h3>
                <p>
                  Personal data provided will be kept whilst there is a business
                  relationship or it is necessary to carry out our Services,
                  including to comply with our legal requirements.
                </p>
                <p>
                  Once it is no longer necessary to keep your personal data it
                  will be deleted.{" "}
                </p>
                <p>
                  In certain circumstances, we may anonymize your personal data
                  (so that it can no longer be associated to you) for research
                  purposes or statistical analysis. In this case, we can use
                  this information indefinitely without prior notice.{" "}
                </p>
                <p>
                  Under GDPR Article 17(3)(b), legal requirements imposed on us
                  must take precedence over the right to be forgotten. The EU’s
                  4th Anti-Money Laundering Directive introduced the requirement
                  that both customer due diligence and transaction records be
                  retained for 5 years after the end of the customer
                  relationship.
                </p>
                <p>
                  Since you have made transactions with us, we must hold onto
                  your data for the prescribed period of time.
                </p>
                <p>
                  The personal data (information which can be used to directly
                  or indirectly identify a person) that we must keep for every
                  customer that has invested with us, is the following:
                </p>
                <ul>
                  <li>Full Legal Name</li>
                  <li>Tax Number</li>
                  <li>Birthdate</li>
                  <li>Email</li>
                  <li>Phone</li>
                  <li>Address</li>
                  <li>Country</li>
                  <li>Nationality</li>
                  <li>Information included Identification documentation</li>
                  <li>Bank Details</li>
                </ul>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  Communication or transfer of data
                </h3>
                <p>
                  We may share your Personal Data with selected third parties
                  included below and those which we collaborate with, including
                  business suppliers, associated aggregators, distributors and
                  subcontractors, when it is necessary for the development,
                  maintenance and control of a legally established relationship,
                  or when there is a legal basis to do it.
                </p>
                <p>
                  Our collaborating companies only collect, use and disclose
                  your information in the ways indicated by us in order to
                  provide adequate services.
                </p>
                <ul>
                  <li>
                    To Euro Fin Management SRL, in such as it is necessary to
                    provide the Services.
                  </li>
                  <li>To P2P Providers added to the Service.</li>
                  <li>To payment initiation service companies.</li>
                  <li>
                    To payment or electronic money entities which facilitate the
                    opening and subsequent use of segregated accounts, including
                    services relating to payments for maintaining accounts.
                  </li>
                  <li>
                    To service providers who deliver solutions for
                    administration systems and IT which allow us to offer our
                    Service.
                  </li>
                  <li>
                    To relevant government bodies, authorities and regulatory
                    bodies in order to comply with our regulatory and reporting
                    obligations.
                  </li>
                  <li>
                    To our professional advisors including lawyers, accountants
                    and advisors who deliver consulting, finance, legal,
                    security and accountancy advice.
                  </li>
                  <li>To any other parties connected with your investments.</li>
                  <li>
                    To market research companies to assist us in improving our
                    Services.
                  </li>
                  <li>
                    To third parties that take part in the User debt collection
                    process (such as debt collectors, lawyers, bailiffs,
                    insolvency administrators etc.).
                  </li>
                  <li>
                    To third party subcontracted suppliers which have
                    established agreements.
                  </li>
                  <li>
                    To third parties who deliver legal, accounting or audit
                    services
                  </li>
                  <li>
                    To any other party where we have your consent or it is
                    required by law
                  </li>
                </ul>
                <p>
                  Additionally, they can share your data with other third
                  parties for other purposes when they have legally acquired
                  your prior consent.
                </p>
                <p>
                  We require that all those third parties transfer your data to
                  respect the security of your personal data and they treat it
                  in accordance with the law. We only permit such third parties
                  to process your personal data for specific purposes and under
                  our instructions.{" "}
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  ‍Keeping Your Personal Information Secure
                </h3>
                <p>
                  ‍We take the security of your personal information very
                  seriously and have appropriate physical, technical and
                  administrative procedures in place to help protect your
                  personal information from unauthorised access, use or
                  disclosure as required by law in Estonia. Once we have
                  received your user information, we will use strict procedures
                  and security features to try to prevent unauthorised access
                  including: by encrypting any Personal Data which we transfer
                  to our partners; and having in place an agreement with our
                  partners which requires it to have in place appropriate
                  measures to safeguard the security of the Personal Data we
                  send to them.
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  ‍Your Rights
                </h3>
                <p>
                  <span className={styles.intalSub}>
                    Requests to exercise your rights
                  </span>
                </p>
                <p>
                  You can, at any moment, revoke the given agreement or request
                  to exercise the following rights to Smart Monday:
                </p>
                <ul>
                  <li>
                    The right to be provided with certain information regarding
                    how we collect, use and share your personal information and
                    our legal basis for doing so.
                  </li>
                  <li>
                    The right to access the personal information we are using.
                  </li>
                  <li>
                    The right to ask about the collection and use of your
                    personal data.
                  </li>
                  <li>
                    The right to rectify when there is incomplete or inaccurate
                    information.
                  </li>
                  <li>
                    The right to eliminate your personal data in certain cases
                    such as when removing your consent, when the treatment of
                    your data is illegal or when your data is no longer
                    necessary.
                  </li>
                  <li>
                    The right to request limits to the treatment of your data in
                    certain cases such as when you contest the accuracy of your
                    data or when the treatment of your data is illegal.
                  </li>
                  <li>
                    The right to receive an electronic copy of your personal
                    data or request that a copy of your personal data is sent
                    directly to another person.
                  </li>
                  <li>
                    The right to oppose the treatment of your data in certain
                    circumstance.
                  </li>
                </ul>
                <p>
                  To exercise these rights, you can make your request in
                  writing, accompanied with a photocopy of your ID card or
                  another identity document to either of the following address:
                </p>
                <ul>
                  <li>
                    By post: Jarvevana tee 9-40, Kesklinna linnaosa, Tallinn,
                    Harju maakond, 11314, Estonia.
                  </li>
                  <li>By email: privacy@techconmgmtpartners.com</li>
                </ul>
                <p>No fee usually required</p>
                <p>
                  You will not have to pay a fee to access your personal data
                  (or to exercise any of the other rights). However, we may
                  charge a reasonable fee if your request is clearly unfounded,
                  repetitive or excessive. Alternatively, we could refuse to
                  comply with your request in these circumstances.
                </p>
                <p>What we may need from you</p>
                <p>
                  We may need to request specific information from you to help
                  us confirm your identity and ensure your right to access your
                  personal data (or to exercise any of your other rights). This
                  is a security measure to ensure that personal data is not
                  disclosed to any person who has no right to receive it. We may
                  also contact you to ask you for further information in
                  relation to your request to speed up our response.
                </p>
                <p>Time limit to respond</p>
                <p>
                  We try to respond to all legitimate requests within one month.
                  Occasionally it could take us longer than a month if your
                  request is particularly complex or you have made a number of
                  requests. In this case, we will notify you and keep you
                  updated.
                </p>
                <p>
                  <span className={styles.intalSub}>
                    Making a complaint to a supervisory authority{" "}
                  </span>
                </p>
                <p>
                  Should you be dissatisfied with the service we provide, You
                  have the right to file a formal complaint to the appropriate
                  Control Authority office.{" "}
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  ‍International transfer of data
                </h3>
                <p>
                  We inform you that some of our suppliers and selected third
                  parties in this Policy are situated outside of the European
                  Economic Area (EEA). As such, the processing of personal data
                  may be associated with an international transfer outside the
                  EEA.
                </p>
                <p>
                  In the case of such transfers, we will provide contractual
                  guarantees in which the receiver of personal data will ensure
                  an adequate level of protection as required in the EEA.
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  ‍Third Party Links
                </h3>
                <p>
                  This Privacy Policy, along with the Services we provide can
                  contain links to third party web pages, applications or
                  plugins. Clicking on those links or enabling those connections
                  may allow third parties to collect or share data about you. We
                  do not control these third party websites and are not
                  responsible for their privacy policies. When you leave the
                  Smart Monday platform, we recommend that you read the privacy
                  policy of every website you visit.
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  ‍Changes to the Policy
                </h3>
                <p>
                  In the case of modifying this policy, we will publish here the
                  changes with a date on which the change was made. If we make a
                  significant change, we will notify you through other means
                  such as by email or by publishing a notice on our webpage.
                </p>
              </article>
              <article className="py-4">
                <h3
                  className={
                    styles.subtittlegreen +
                    " pb-0 pt-0 w-100 text-justify mx-auto"
                  }
                >
                  ‍Cookies Policy
                </h3>
                <p>
                  <span className={styles.subUl}>
                    Information about our use of cookies
                  </span>
                </p>
                <p>
                  We collect data about how you interact with our website
                  through the use of cookies. Our website uses cookies to
                  distinguish you from other users of our website. By continuing
                  to browse our website, you are agreeing to our use of cookies.
                </p>
                <p>
                  A cookie is a small file of letters and numbers that we store
                  on your browser or the hard drive of your computer if you
                  agree. Cookies contain information that is transferred to your
                  computer's hard drive.
                </p>
                <p>We use the following cookies:</p>
                <p>
                  <span className={styles.subUl}>
                    Strictly necessary cookies.
                  </span>{" "}
                  These are cookies that are required for the operation of our
                  website. They include, for example, cookies that enable you to
                  log into secure areas of our website, use a shopping cart or
                  make use of e-billing services.
                </p>
                <p>
                  <span className={styles.subUl}>
                    Analytical/performance cookies.
                  </span>{" "}
                  They allow us to recognise and count the number of visitors
                  and to see how visitors move around our website when they are
                  using it. This helps us to improve the way our website works,
                  for example, by ensuring that users are finding what they are
                  looking for easily.
                </p>
                <p>
                  <span className={styles.subUl}>Functionality cookies.</span>{" "}
                  These are used to recognise you when you return to our
                  website. This enables us to personalise our content for you,
                  greet you by name and remember your preferences (for example,
                  your choice of language or region).
                </p>
                <p>
                  <span className={styles.subUl}>Targeting cookies.</span> These
                  cookies record your visit to our website, the pages you have
                  visited and the links you have followed. We will use this
                  information to make our website and the advertising displayed
                  on it more relevant to your interests. We may also share this
                  information with third parties for this purpose.
                </p>
                <p>
                  You can find more information about the individual cookies we
                  use and the purposes for which we use them in the table below:
                </p>
                <table>
                  <tr>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Purpose</strong>
                    </th>
                    <th>
                      <strong>Own/Third Party</strong>
                    </th>
                    <th>
                      <strong>Additional information</strong>
                    </th>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>Analytics</td>
                    <td>Third party (Google Analytics)</td>
                    <td>Used to differentiate users</td>
                  </tr>
                  <tr>
                    <td>_gat</td>
                    <td>Analytics</td>
                    <td>Third party (Google Analytics)</td>
                    <td>Limit number of requests</td>
                  </tr>
                  <tr>
                    <td>_gid</td>
                    <td>Analytics</td>
                    <td>Third party (Google Analytics)</td>
                    <td>Used to differentiate users</td>
                  </tr>
                  <tr>
                    <td>_cookienotice</td>
                    <td>Internal</td>
                    <td>Own</td>
                    <td>Cookie policy acceptance</td>
                  </tr>
                  <tr>
                    <td>UserFrosting</td>
                    <td>Auth/Session token</td>
                    <td>Own</td>
                    <td>
                      Identify all the requests belonging to a same session
                    </td>
                  </tr>
                  <tr>
                    <td>1P_JAR</td>
                    <td>Analytics</td>
                    <td>Third party (Google Analytics)</td>
                    <td>Set a unique ID to access gsuite services</td>
                  </tr>
                  <tr>
                    <td>_cfduid</td>
                    <td>Internal</td>
                    <td>Third party (jQuery)</td>
                    <td>Needed by jQuery</td>
                  </tr>
                  <tr>
                    <td>_cfduid</td>
                    <td>Security</td>
                    <td>Third party (Cloudflare)</td>
                    <td>Identify individual clients, remember user settings</td>
                  </tr>
                  <tr>
                    <td>_biz_flasgA</td>
                    <td>Security</td>
                    <td>Third party (Cloudflare)</td>
                    <td>Identify individual clients, remember user settings</td>
                  </tr>
                  <tr>
                    <td>_biz_nA</td>
                    <td>Security</td>
                    <td>Third party (Cloudflare)</td>
                    <td>Identify individual clients, remember user settings</td>
                  </tr>
                  <tr>
                    <td>_biz_pendingA</td>
                    <td>Security</td>
                    <td>Third party (Cloudflare)</td>
                    <td>Identify individual clients, remember user settings</td>
                  </tr>
                  <tr>
                    <td>_biz_uid</td>
                    <td>Security</td>
                    <td>Third party (Cloudflare)</td>
                    <td>Identify individual clients, remember user settings</td>
                  </tr>
                  <tr>
                    <td>_mkto_trk</td>
                    <td>Security</td>
                    <td>Third party (Cloudflare)</td>
                    <td>Identify individual clients, remember user settings</td>
                  </tr>
                </table>
                <p>
                  <br></br>
                  <span className={styles.subUl}>Third parties</span>
                </p>
                <p>
                  Please note that third parties (including, for example,
                  advertising networks and providers of external services like
                  web traffic analysis services) may also use cookies, over
                  which we have no control. These cookies are likely to be
                  analytical/performance cookies or targeting cookies.
                </p>
                <p>
                  <span className={styles.subUl}>
                    How do I change my cookie settings?
                  </span>
                </p>
                <p>
                  You can block cookies by activating the setting on your
                  browser that allows you to refuse the setting of all or some
                  cookies. However, if you use your browser settings to block
                  all cookies (including essential cookies) you may not be able
                  to access all or parts of our site.
                </p>
                <p>
                  You can find out more information about cookies at
                  <a href="https://www.allaboutcookies.org">
                    {" "}
                    www.allaboutcookies.org
                  </a>
                </p>
                <p>
                  To opt-out of being tracked by Google Analytics across all
                  websites, visit
                  <a href="http://tools.google.com/dlpage/gaoptout">
                    {" "}
                    http://tools.google.com/dlpage/gaoptout
                  </a>
                </p>
              </article>
            </Col>
          </Row>
        </Layout>
      </section>
    )
  }
}
export default PrivacyPolicy
